<template>
  <div>

    <page-header recommendations="hide"></page-header>
    
    <v-row dense>
      <v-col
        v-if="$hasRole('SUPERUSER') || $hasRole('ADMIN') || $hasRole('TECHNICAL')"
        cols="12"
        md="6"
      > 
        <v-card flat class="mb-2 pa-4 fill-height" 
            :to="'/tech'"
          >
            <v-row class="fill-height d-flex align-center" no-gutters>
              <v-col
                cols="2"
                class="d-flex align-center justify-center"
              >
                <v-icon
                    x-large
                    color="#555"
                  >fa-tools</v-icon>
              </v-col>
              <v-col
                cols="10"
                class="d-flex flex-column justify-center">
                <v-card-title class="ma-0 pb-0" style="color:#0068a4; font-weight: 600;">
                  Technical SEO
                </v-card-title>
                <v-card-text class="text-body-2 grey--text text--darken-8">
                  Optimize site structure for better crawling, indexing and speed.
                </v-card-text>
              </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="$hasRole('SUPERUSER') || $hasRole('ADMIN') || $hasRole('CONTENT')"
        cols="12"
        md="6"
      >
        <v-card flat class="mb-2 pa-4 fill-height"
          :to="'/content'"
        >
          <v-row class="fill-height d-flex align-center" no-gutters>
            <v-col
              cols="2"
              class="d-flex align-center justify-center"
            >
              <v-icon
                  x-large
                  color="#555"
                >fa-edit</v-icon>
            </v-col>
            <v-col
              cols="10"
              class="d-flex flex-column justify-center">
              <v-card-title class="ma-0 pb-0" style="color:#0068a4; font-weight: 600;">
                Content SEO
              </v-card-title>
              <v-card-text class="text-body-2 grey--text text--darken-8">
                Create high quality content for ranking in search engines.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    
    </v-row>

    <v-row dense>

      <!-- Documentation -->
      <v-col
        cols="12"
        md="6"
      >
        <v-card flat class="mb-2 pa-4 fill-height"
          href="https://cdseo.com/blogs/documentation"
          target="_blank"
        >
          <v-row class="fill-height d-flex align-center" no-gutters>
            <v-col
              cols="2"
              class="d-flex align-center justify-center"
            >
              <v-icon
                  x-large
                  color="#555"
                >fa-question-circle</v-icon>
            </v-col>
            <v-col
              cols="10"
              class="d-flex flex-column justify-center">
              <v-card-title class="ma-0 pb-0" style="color:#0068a4; font-weight: 600;">
                Documentation
              </v-card-title>
              <v-card-text class="text-body-2 grey--text text--darken-8">
                Discover how to use CDSEO effectively to maximize your results.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Settings -->
      <v-col
        cols="12"
        md="6"
      >
        <v-card flat class="mb-2 pa-4 fill-height"
          to="/settings"
        >
          <v-row class="fill-height d-flex align-center" no-gutters>
            <v-col
              cols="2"
              class="d-flex align-center justify-center"
            >
              <v-icon
                x-large
                color="#555"
              >fa-cog</v-icon>
            </v-col>
            <v-col
              cols="10"
              class="d-flex flex-column justify-center">
              <v-card-title class="ma-0 pb-0" style="color:#0068a4; font-weight: 600;">
                Settings
              </v-card-title>
              <v-card-text class="text-body-2 grey--text text--darken-8">
                Adjust your preferences and personalize your account settings.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

    </v-row>

    <dashboard-manage-users
      class="mt-3"
      v-if="$hasRole('ADMIN')"
    >
    </dashboard-manage-users>

    <div class="mt-8 text-center caption grey--text">
      <a href="https://cdseo.com" target="_blank" style="color:inherit;text-decoration:none;">&copy; CDSEO, LLC</a>
      &nbsp;
      <a href="https://cdseo.com/blogs/documentation" target="_blank" style="color:inherit;text-decoration:none;">v1.0.0-beta.1</a>
    </div>

  </div>
</template>

<style scoped>
.dashboard-icon-tech,
.dashboard-icon-content {
  min-height: 230px;
  z-index: 1;
}
.dashboard-icon-tech:before {
  position: absolute;
  font-family: 'FontAwesome';
  right: 50px;
  top: 0px;
  opacity: 1;
  font-size: 140px;
  z-index: -1;
  color: #e9eef5;
  content: "\f7d9";
}
.dashboard-icon-content:before {
  position: absolute;
  font-family: 'FontAwesome';
  right: 50px;
  top: 0px;
  opacity: .8;
  font-size: 140px;
  z-index: -1;
  color: #e9eef5;
  content: "\f044";
}
</style>

<script>
import PageHeader from '@/components/PageHeader'
import DashboardManageUsers from './DashboardManageUsers.vue';

export default {
  components: {
    PageHeader,
    DashboardManageUsers
  },
  data: () => ({
    panel: 0,
  }),
  created () {
    // SUPERUSERS and TECHNICAL/CONTENT users both can see main dashboard
    if (
      this.$hasRole('SUPERUSER') ||
      (
        this.$hasRole('TECHNICAL') &&
        this.$hasRole('CONTENT')
      )
    ) {
      return
    } 
    // CONTENT users can only see ContentDashboard
    else if (this.$hasRole('CONTENT')) {
      this.$router.replace({ name: 'ContentDashboard' })
    } 
    // Else send TECHNICAL users to the TechnicalDashboard
    else {
      this.$router.replace({ name: 'TechnicalDashboard' })
    }
  }
}
</script>