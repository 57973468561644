<template>
  <div
    @click.stop
  >
    <div
      class="pa-5 d-flex align-center"
      style="border-bottom: 1px #efefef solid;"
    >
      <component
        :is="to ? 'router-link' : 'div'"
        class="flex-grow-1 align-center"
        style="cursor: pointer; color: inherit; text-decoration: none;"
        v-bind="to ? { to } : {}"
      >
        <div
          @click="destroy"
        >
          <div class="body-2 font-weight-bold">
            {{ description }}
          </div>
          <div
            v-if="record.created_at"
            class="caption"
          >
            {{ record.created_at | formatDate('MM/DD/YY') }}
            <!-- JON: Removing time for now {{ record.created_at | formatDate('h:mm a') }} -->
          </div>
        </div>
      </component>
      <div
        class="pl-2"
      >
        <v-btn
          icon
          @click.stop.prevent="destroy"
          :loading="loading"
          small
        >
          <v-icon small>fa fa-times</v-icon>
        </v-btn>
      </div>
    </div>
    <v-progress-linear
      v-if="loading"
      height="1"
      indeterminate
      color="primary"
    />
  </div>

</template>

<script>
import { Alert, Page } from '@/models'
import moment from 'moment-timezone'

export default {
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    description () {
      if (this.loading) {
        return ''
      }
      let dueAtFormatted = this.data?.due_at ? moment(this.data.due_at).format('MMM D, YYYY') : null
      let dueAtRelative = this.data?.due_at_relative
      switch (this.record?.key) {
        case '404-errors':
          return `${this.data.count || 0} broken link${this.data.count !== 1 ? 's' : ''}`
        case 'autopopulation-run':
          return 'Autopopulation has been Completed'
        case 'content-assigned':
          return `Content Assigned: ${this.pageName}${dueAtFormatted ? ' | Due: ' + dueAtFormatted : ''}`
        case 'content-due':
          return `Content ${dueAtRelative === 'Overdue' ? 'Overdue' : 'Due'}: ${this.pageName}${dueAtRelative === 'Overdue' ? ' | ' + dueAtFormatted : ' | ' + dueAtRelative}`
        case 'content-review':
          return `Content Ready for Review: ${this.pageName}`
      }
      return '?'
    },
    data () {
      try {
        return JSON.parse(this.record.data || '{}')
      } catch (e) {
        //
        return {}
      }
    },
    pageName () {
      const page = Page.find(this.data?.page_id)
      return page?.name || ('Page #' + this.data?.page_id)
    },
    to () {
      switch (this.record?.key) {
        case '404-errors':
          return {
            name: 'TechnicalRepairLinks'
          }
        case 'autopopulation-run':
          return {
            name: 'TechnicalOptimizeListings',
            params: {
              autopopulate: 'true'
            }
          }
        case 'content-assigned':
        case 'content-due':
        case 'content-review':
          return {
            name: 'ContentPageEditor',
            params: {
              pageId: this.data.page_id
            }
          }
      }
      return undefined
    }
  },
  methods: {
    async destroy () {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        await Alert.destroy(this.record)
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    if (
      ['content-assigned', 'content-review', 'content-due'].includes(this.record?.key) &&
      this.data?.page_id
    ) {
      this.loading = true
      try {
        await Page.fetchOne(this.data.page_id, { multiplex: true })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>