import Model from './Model'

export default class Integration extends Model {
  static entity = 'integration'

  static fields () {
    return {
      id: this.attr(null),
      account_id: this.attr(null),
      type: this.attr(null),
      identifier: this.attr(''),
      public: this.attr(''),
      private: this.attr(''),
      created_at: this.attr('')
    }
  }
}