<template>
  <v-dialog
    :value="show"
    max-width="500"
  >
    <v-card>
      <v-card-title class="grey lighten-2" style="word-break: break-word;">
        You're almost done...
      </v-card-title>
      <v-card-text
        class="pa-3"
      >
        <div
          v-if="$store.getters.shopifyIntegration"
        >
        <!-- JON: Probably good to show an image of how/where -->
          <p>Please enable the Shopify App Embed block.  Once it's enabled 
            and you refresh your store's page this reminder will no longer appear.</p>
        </div>
        <div
          v-else
        >Shopify Integration Not Found</div>
      </v-card-text>
      <v-card-actions class="grey lighten-5">
        <v-spacer></v-spacer>
        <v-btn
          plain
          @click="show = false"
        >Remind Me Later</v-btn>
        <v-btn
          :href="deepLinkUrl"
          color="primary"
          target="_blank"
        >Shopify Theme Editor</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Integration } from '@/models'

const themeAppUuid = '318bee16-9c6e-4809-8ba1-8e58c6d0d6ee'

export default {
  data () {
    return {
      show: true
    }
  },
  computed: {
    deepLinkUrl () {
      return `https://${this.$store.getters.shopifyIntegration?.identifier}/admin/themes/current/editor?context=apps&activateAppId=${themeAppUuid}/cdseo`
    }
  }
}
</script>
