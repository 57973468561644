import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@fortawesome/fontawesome-free/css/all.css'
import router from './router'
import store from './plugins/store'
import globalMixin from './plugins/globalMixin'
import moment from 'moment-timezone'

Vue.config.productionTip = false

// My intention with these filters is to abstract anything to do with momentjs into one place and then
// replace it with a more modern library.
Vue.filter('formatDate', (value, format = 'YYYY-MM-DD') => {
  if (!value) {
    return ''
  }
  return moment(value)
    .tz(moment.tz.guess())
    .format(format)
})

Vue.filter('formatTimestamp', (value, format = 'MMM D, YYYY h:mm a z') => {
  if (!value) {
    return ''
  }
  return moment(value)
    .tz(store.getters?.user?.timezone || store.getters?.account?.timezone || moment.tz.guess())
    .format(format)
})

// This displays "21 hours ago" up to a day and then displays the localized timestamp if longer ago than that
Vue.filter('recentDate', (value, format = null) => {
  if (!value) {
    return ''
  }
  format = format || 'lll'
  return moment(value)
    .tz(moment.tz.guess())
    .format(format)
})

Vue.filter('capitalize', (value) => {
  if (!value) return ""
  const words = value.toLowerCase().split(" ")
  return words.map((word) => { 
      return word[0].toUpperCase() + word.substring(1); 
  }).join(" ")
})

Vue.filter('prettyBytes', function (num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    return num
  }

  let exponent
  let unit
  let neg = num < 0;
  let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
  unit = units[exponent]

  return (neg ? '-' : '') + num + ' ' + unit
})

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
})

Vue.mixin(globalMixin)

// Prevent ctrl + k on windows from doing what it usually does
document.onkeydown = function (e) {
  if (e.ctrlKey && e.code === 'KeyK') {
    e.preventDefault()
    e.stopPropagation()
  }
}

const run = async () => {
  await store.dispatch('getSession')

  new Vue({
    vuetify,
    router,
    store,
    created () {
      window.$vuetify = this.$vuetify
    },
    render: h => h(App)
  }).$mount('#app')
}

run()
