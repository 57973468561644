<template>
  <div>

    <div
      class="page-header d-flex mb-4"
    >
      <div
        class="flex-grow-1"
      >
        <!-- Breadcrumbs -->
        <div
          class="section-name"
        >
          <router-link
            :to="{ path: '/' }"
          >
            {{ $store.getters.account.name }}
          </router-link>
          <span
            class="fence" 
            v-if="$route.meta.breadcrumb_path"
          >
            /
          </span>
          <router-link
            v-if="$route.meta.breadcrumb_path"
            class="text-capitalize"
            :to="{ path: $route.meta.breadcrumb_path }"
          >
          {{ $route.meta.breadcrumb_name }}
          </router-link>
        </div>

        <!-- Page Title -->
        <div
          class="page-name"
        >
          {{ page || ($route.meta && $route.meta.name) || $route.name }}
        </div>

      </div>

      <div class="d-flex align-end justify-space-between">
        <div class="d-flex align-center">

          <!-- Menu Slot -->
          <div
            v-if="$slots.right"
            class="mr-4"
          >
            <slot
              name="right"
            />
          </div>

          <!-- Branding Logo -->
          <div class="d-none d-lg-block">
            <a href="https://cdseo.com" target="_blank" title="CDSEO Home Page"><img src="@/assets/logos/cdseo-logo-blue.png" width="180" height="45" class="CDSEO" /></a>
          </div>

          <alert-menu 
            v-if="$vuetify.breakpoint.lgAndUp"
          />
        </div>
      </div>
    </div>

    <slot />

  </div>

</template>

<script>
import AlertMenu from './AlertMenu/AlertMenu.vue'

export default {
  components: {
    AlertMenu
  },
  props: {
    page: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.page-header {
  .section-name {
    color: $primary;
    font-weight: bold;
    padding-left: 2px;
    a {
      font-size: 16px;
      text-decoration: none;
    }
    .fence {
      font-size: 16px;
      padding-left: 2px;
      padding-right: 2px;
      font-weight: normal;
      color: $primary;
    }
  }
  .page-name {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  @media (max-width: 600px) {
    .page-name {
      font-size: 28px;
      line-height: 28px;
    }
  }
}
</style>